<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import type { InvoiceFlag } from "@planetadeleste/vue-mc-gw/src/types";

@Component({})
export default class InvoiceAlertMsg extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
  @Prop(Boolean) readonly inDialog!: boolean;

  dialog = false;

  get flag(): InvoiceFlag | null | undefined {
    return this.item.flag;
  }

  get msg() {
    return this.item.error_msg;
  }

  get display() {
    return this.flag && ["validation_error", "sign_error"].includes(this.flag);
  }

  get sColor() {
    if (!this.flag) {
      return "success";
    }

    // @ts-ignore
    return this.flag === "validation_error" ? "warning" : "error";
  }

  onClose() {
    this.dialog = false;
  }
}
</script>

<template>
  <div v-frag>
    <template v-if="display && inDialog">
      <v-dialog v-if="msg" v-model="dialog" max-width="500px">
        <template #activator="{ on }">
          <v-btn icon v-on="on" :color="sColor">
            <v-icon>mdi-alert</v-icon>
          </v-btn>
        </template>

        <v-alert :type="sColor">
          <template #close>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <p v-text="msg" />
        </v-alert>
      </v-dialog>
      <v-icon v-else :color="sColor">mdi-alert</v-icon>
    </template>

    <v-alert v-else-if="display" text :type="sColor">
      <p v-text="msg" />
    </v-alert>
  </div>
</template>
